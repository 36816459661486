import { getIsHeatmapShown } from "../api/trello-api"
import { PUP_DISPLAY_NAME } from "../config"
import { type CapabilityProps } from "../types/power-up"
import { type Trello } from "../types/trello"

const defaultHeight = 250

export async function getCardBackSection(
  t: Trello.PowerUp.IFrame,
  props: CapabilityProps
): Promise<Trello.PowerUp.CardBackSection | undefined> {
  const isHeatmapShown = await getIsHeatmapShown(t)

  if (!isHeatmapShown) {
    return
  }

  return {
    title: PUP_DISPLAY_NAME,
    icon: props.baseUrl + props.icon.dark,
    content: {
      type: "iframe",
      height: defaultHeight,
      url: t.signUrl("./card-back-section.html"),
    },
  }
}
