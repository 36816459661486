import { type Trello } from "../types/trello"
import { type CapabilityProps } from "../types/power-up"
import { PUP_DISPLAY_NAME } from "../config"

export async function getCardButton(
  t: Trello.PowerUp.IFrame,
  props: CapabilityProps
): Promise<Trello.PowerUp.CardButton[]> {
  return [
    {
      icon: props.baseUrl + props.icon.dark,
      text: PUP_DISPLAY_NAME,
      callback: (t: Trello.PowerUp.IFrame) => {
        return t.popup({
          title: PUP_DISPLAY_NAME,
          url: "./card-button.html",
          height: 120
        })
      }
    }
  ]
}
