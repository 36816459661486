import { CapabilityProps } from "./types/power-up"
import { Trello } from "./types/trello"

const PUP_DISPLAY_NAME = "Activity Calendar"
const PUP_ID = "6"

const CAPABILITY_PROPS: CapabilityProps = {
  baseUrl: window.location.href.replace(/\/$/, ""),
  logo: {
    dark: "/static/mermaid-icon.png",
    light: "/static/mermaid-icon.png"
  },
  icon: {
    dark: "/static/cal.svg",
    light: "/static/cal.svg"
  },
  authorizeIcon: {
    dark: "/static/authorize-icon.svg",
    light: "/static/authorize-icon.svg"
  },
  upgradeIcon: {
    dark: "/static/upgrade-icon.svg",
    light: "/static/upgrade-icon.svg"
  }
}

const HELP_ACTION = {
  icon: "https://trello.bluedashersoftware.com/static/help.svg",
  alt: "help",
  position: "right" as "right" | "left",
  callback: (t: Trello.PowerUp.IFrame) => {
    return t.popup({
      title: "Help Center",
      url: "./help.html",
      height: 300
    })
  }
}

export {
  PUP_DISPLAY_NAME,
  PUP_ID,
  CAPABILITY_PROPS,
  HELP_ACTION
}
