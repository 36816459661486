import { Trello } from "../types/trello";

export const getIsHeatmapShown = async (t: Trello.PowerUp.IFrame): Promise<boolean> => {
    const showHeatmap = await t.get("card", "shared", "show-heatmap")
    return showHeatmap || false
}

export const updateIsHeatmapShown = async (t: Trello.PowerUp.IFrame, showHeatmap: boolean): Promise<boolean> => {
    await t.set("board", "shared", "show-heatmap", showHeatmap)
    return showHeatmap
}


