import { type Trello } from "../types/trello"
import { HELP_ACTION, PUP_DISPLAY_NAME } from "../config"

export async function getOnEnable(t: Trello.PowerUp.IFrame): Promise<void> {
  return t.modal({
    title: `${PUP_DISPLAY_NAME}`,
    url: "./onboarding.html",
    actions: [HELP_ACTION]
  })
}
